export async function dqueryAppSetting ()
{
    ////console.log ('accessToken: ' + accessToken);
    
    try {
 
        const response = await fetch('https://6icp1eg37h.execute-api.ca-central-1.amazonaws.com/prod/app-setting', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        });

      
        if (response.ok) {
            // Handle success
            const appSettingResData = await response.json();
        
            return appSettingResData;
        } else {
            // Handle error
            console.error('Error:', response.status, response.statusText);
        
            return {success: false};
        }
    
    } catch (error) {
        console.error('Error:', error);
        return {success: false};
    }
}


export async function dquery (action:string)
{
    ////console.log ('accessToken: ' + accessToken);

    try {
 
        const response = await fetch('https://tklesmy0d3.execute-api.ca-central-1.amazonaws.com/prod/dquery?a=' + action, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        });

      
        if (response.ok) {
            // Handle success
            const dqueryRes = await response.json();
        
            return dqueryRes;
        } else {
            // Handle error
            console.error('Error:', response.status, response.statusText);
        
            return {success: false};
        }
    
    } catch (error) {
        console.error('Error:', error);
        return {success: false};
    }
}


